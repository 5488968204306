jQuery(document).ready(function ($) {

    var bodyClassSelect = document.querySelector('body');
    if (bodyClassSelect.classList.contains('manniello-single-theme') &&
        bodyClassSelect.classList.contains('home')) {
        // TripAdvisor only Single theme
        var io_first_target = document.querySelector('.trip-second-io');
        var io_second_target = document.querySelector('.intersect-observer-bottom');
        // Block watching load
        var trip_container = document.querySelector('.tripadivsor-container');
        var insert_widget_trip = $('.tripadivsor-container');
        if (insert_widget_trip.length) {

            function loadAjaxTrip() {
                data = {
                    'action': 'tripwidget',
                    // 'nonce': wp_ajax.nonce,   // Nonce
                };

                $.ajax({
                    url: wp_ajax.ajax_url,
                    data: data,
                    type: 'POST',

                    success: function (data) {
                        if (data) {
                            insert_widget_trip.html(data);
                            console.log('TripAdvisor Widget Intersected & Loaded');
                        } else {
                            console.log('Error Loading Tripadvisor Widgets');
                        }
                    }

                });
            }

            if ('IntersectionObserver' in window) {

                var options = {
                    root: null,
                    rootMargin: '300px 0px 400px 0px',
                    //threshold size in % of the image that need to read to start the lazy loading
                    threshold: 0.01
                };

                var observer = new IntersectionObserver(
                    function (entries, observer) {
                        var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                        if (isIntersecting) {
                            loadAjaxTrip();
                            // When reload the webpage in this place it wont load for the next function
                            if (io_first_target != null) {
                                observer.unobserve(io_first_target);
                            }
                            observer.unobserve(io_second_target);
                            observer.unobserve(trip_container);
                        }
                    },
                    options
                );

                // Observer with two points of observer. Top and Bottom
                if (io_first_target != null) {
                    observer.observe(io_first_target);
                }
                observer.observe(io_second_target);
                observer.observe(trip_container);

            } else {
                loadAjaxTrip();
            }

        }
    }

    var widget_first_target = document.querySelector('.widget_first_target');
    // Leave two IO observing the same div
    var widget_second_target = document.querySelector('.widget_second_target');
    var insert_widget = $('.footer-newsletter-right');
    var enableChangeListId = true;
    // False if you want to load with AJAX. Right now is with manniello_newsletter_lazy
    // Better for the apply_filters changing the ID
    if (insert_widget.length && !enableChangeListId) {

        function loadMailUp() {
            data = {
                'action': 'loadwidget',
                // 'nonce': wp_ajax.nonce,   // Nonce
            };

            $.ajax({
                url: wp_ajax.ajax_url,
                data: data,
                type: 'POST',

                success: function (data) {
                    if (data) {
                        insert_widget.html(data);
                    } else {
                        console.log('Error Loading Widgets');
                    }
                }
            });
            console.log('MailUp Widget Intersected and Loaded');
        }

        if ('IntersectionObserver' in window) {

            var options = {
                root: null,
                rootMargin: '300px 0px 400px 0px',
                //threshold size in % of the image that need to read to start the lazy loading
                threshold: 0.01
            };

            var observer = new IntersectionObserver(
                function (entries, observer) {
                    var isIntersecting = typeof entries[0].isIntersecting === 'boolean' ? entries[0].isIntersecting : entries[0].intersectionRatio > 0;
                    if (isIntersecting) {
                        loadMailUp();
                        // if (widget_first_target != null) {
                        //     observer.unobserve(widget_first_target);
                        // }
                        observer.unobserve(widget_second_target);
                    }
                },
                options
            );

            // Observer with two points of observer. Top and Bottom
            // if (widget_first_target != null) {
            //     observer.observe(widget_first_target);
            // }
            observer.observe(widget_second_target);

        } else {
            loadMailUp();
        }

    }


});
